import * as React from "react";
import { Link } from "gatsby";

const Test = ({ heroTitle, description }) => {
  return (
    <section className="py-5 text-center container">
      <div className="row py-lg-5">
        <div className="col-lg-12 col-md-8 mx-auto">
          <h1 className="fw-light">{heroTitle}</h1>
        </div>
        <div className="col-lg-12 col-md-8 mx-auto">
          <h1 className="fw-light">{description}</h1>
        </div>
      </div>
      <div className="row">
        <Link to="/about/" className="btn btn-primary my-2">About</Link>
        <Link to="/weather/" className="btn btn-secondary my-2">Go to weather</Link>
      </div>
    </section>
  )
}

export default Test;
