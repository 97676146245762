import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { useStaticQuery, graphql } from "gatsby";
import get from "lodash/get";
import Test from "../components/Test";
import { GatsbyImage } from "gatsby-plugin-image";
import {getImageAltText} from "../utils/common";

const TestPage = () => {
    const data = useStaticQuery(
      graphql`
      query {
        allContentfulHomepage(filter: {node_locale: {eq: "uk-UA"}}) {
          edges {
            node {
              heroTitle
              description {
                description
              }
            }
          }
        }
        allContentfulAsset(filter: {node_locale: {eq: "uk-UA"}}) {
          edges {
            node {
              id
              title
              fullWidth: gatsbyImageData(layout: FULL_WIDTH)
              constrained: gatsbyImageData(layout: CONSTRAINED, width: 186)
              fixed: gatsbyImageData(layout: FIXED, width: 100, height: 100)
              dominant: gatsbyImageData(
                layout: CONSTRAINED
                placeholder: DOMINANT_COLOR
                width: 186
              )
              blurred: gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                width: 186
              )
              traced: gatsbyImageData(
                layout: CONSTRAINED
                placeholder: TRACED_SVG
                width: 186
              )
            }
          }
        }
      }     
    `);
    const heroTitle = get(data, 'allContentfulHomepage.edges[0].node.heroTitle');
    const description = get(data, 'allContentfulHomepage.edges[0].node.description.description');
    const assets = get(data, 'allContentfulAsset.edges');
    return (
        <Layout>
            <Seo title="Test" />
            <Test heroTitle={heroTitle} description={description} />

          fullWidth:
          {assets.map(({ node: { id, title, fullWidth } }) => (
            <div key={id}>
              {title}
              <GatsbyImage
                image={fullWidth}
                alt={getImageAltText()}
              />
            </div>
          ))}

          constrained:
          {assets.map(({ node: { id, title, constrained } }) => (
            <div key={id}>
              {title}
              <GatsbyImage
                image={constrained}
                alt={getImageAltText()}
              />
            </div>
          ))}

          fixed:
          {assets.map(({ node: { id, title, fixed } }) => (
            <div key={id}>
              {title}
              <GatsbyImage
                image={fixed}
                alt={getImageAltText()}
              />
            </div>
          ))}

          dominant:
          {assets.map(({ node: { id, title, dominant } }) => (
            <div key={id}>
              {title}
              <GatsbyImage
                image={dominant}
                alt={getImageAltText()}
              />
            </div>
          ))}

          blurred:
          {assets.map(({ node: { id, title, blurred } }) => (
            <div key={id}>
              {title}
              <GatsbyImage
                image={blurred}
                alt={getImageAltText()}
              />
            </div>
          ))}

          traced:
          {assets.map(({ node: { id, title, traced } }) => (
            <div key={id}>
              {title}
              <GatsbyImage
                image={traced}
                alt={getImageAltText()}
              />
            </div>
          ))}
        </Layout>
    )
}

export default TestPage;